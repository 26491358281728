@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_variables.scss';

.LimitsTableWrap {
  overflow: auto;
  margin: 0 -16px;
  @media only screen and (min-width: $desktop-width) {
    overflow: hidden;
    margin: 0;
  }
}
.LimitsTable {
    max-width: 1346px;
    width: 100%;
    margin: 0 auto;
    border: none;
    .LimitTableTdWrapperContainer {
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        line-height: 14px;
        &:first-child {
          margin-bottom: 2px;
        }
        @media only screen and (min-width: $tablet-width) {
            font-size: 16px;
            line-height: 20px;
        }
      }
    }
    .LimitsTableBorder {
      border: solid 1px #0f1d41;
      td {
        border-top: solid 1px #0f1d41;
        border-bottom: solid 1px #0f1d41;
        &:first-child {
          border-left: solid 1px #0f1d41;
        }
        &:last-child {
          border-right: solid 1px #0f1d41;
        }
      }
    }
    .LimitTableTdGreyText {
      opacity: 0.5;
    }
    .LimitTableTdWrapper {
      height: 100%;
      display: flex;
      align-items: center;
      &Column {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        span {
          font-size: 10px;
          line-height: 12px;
          @media only screen and (min-width: $tablet-width) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    .LimitsTableIconWrapper {
      margin: 0 20px 0 0;
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 100%;
        width: auto;
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    tr {
      background: #686868;
      td {
        height: 50px;
        text-align: right;
        font-size: 12px;
        line-height: 14px;
        width: 20%;
        min-width: 160px;
        &:first-child {
          padding: 0 0 0 38px;
          text-align: left;
        }
        /*&:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 20%;
        }*/
        &:last-child {
          padding: 0 38px 0 0;
        }
        @media only screen and (min-width: $tablet-width) {
            font-size: 16px;
            line-height: 20px;
            height: 64px;
        }
      }
    }
    .LimitsTableSep {
      height: 30px;
    }
    .LimitsTableHead {
      background: #404040;
      box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.14);
      &:first-child {
        color: #00ffff;
        th {
            &:last-child {
                border-radius: 0 16px 0 0;
            }
            &:first-child {
                border-radius: 16px 0 0 0;
            }
        }
      }
      th {
        &:first-child {
          text-transform: inherit !important;
        } 
        font-size: 12px;
        line-height: 14px;
        @media only screen and (min-width: $tablet-width) {      
            font-size: 16px;
            line-height: 20px;            
        }
      }
      th,
      td {
        height: 50px;
        text-align: right;
        &:first-child {
          text-align: left;
          padding: 0 0 0 38px;
          letter-spacing: 0.04em;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }
        &:last-child {
          padding: 0 38px 0 0;
        }
        @media only screen and (min-width: $tablet-width) {
            height: 64px;
            &:first-child {
              text-align: left;
              padding: 0 0 0 38px;
              letter-spacing: 0.04em;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
            }
            &:last-child {
              padding: 0 38px 0 0;
            }
        }
      }
    }
  }