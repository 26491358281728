@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































.Limits_wrapperInfo {
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Limits_wrapperItem {
  font-size: 16px;
  line-height: 170%;
  color: #fff;
  span {
    font-weight: bold;
    color: #2af3f3;
  }
  a {
    text-decoration: underline;
    color: #2af3f3;
  }
}
.Limits_wrapperDivider {
  width: 100%;
  height: 1px;
  background: #2af3f3;
  opacity: 0.1;
  margin: 30px 0;
}
.Limits_wrapperSubTitle {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  &::before {
    content: '';
    width: 10px;
    height: 8px;
    background-image: url('../../../assets/img/landing/titleIcon.svg');
    display: block;
    background-position: center;
    background-size: cover;
    margin: 0 6px 0 0;
  }
}
.Limits_wrapper {
  position: relative;
  overflow: hidden;
  padding: 140px 0 120px 0;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;
  &Title {
    color: #2af3f3;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.04em;
    font-weight: bold;
    text-align: left;
    margin: 0 0 18px 0;
  }
}
